import React, { useState, useEffect } from "react";
import "./form_offerings2.css";
import Header from "./Header";
import Navbar from "./Navbar";
const baseUrl = process.env.REACT_APP_BACKEND_API_URL;
export const getAccessToken = () => {
  const cookies = document.cookie.split("; ");
  const accessTokenCookie = cookies.find((cookie) =>
    cookie.startsWith("accessToken=")
  );

  if (accessTokenCookie) {
    const [, accessToken] = accessTokenCookie.split("=");
    return accessToken;
  }

  return null;
};

const MAX_CONTEXT_NAME_LENGTH = 50;
const MAX_CONTENT_LENGTH = 1000;

// TODO: Change a_t of form_testing, messages and Home from hard code to cookie
let a_t = getAccessToken();
if (a_t == null) {
  a_t = "Haha";
}

const Form2 = () => {
  const [fields, setFields] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [newContext, setNewContext] = useState({
    context_name: "",
    content: "",
  });
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState({}); //Track edit mode on/off

  const getContexts = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${a_t}`);

    const requestOptions = {
      method: "GET", // Change to GET method
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseUrl}/linkedin/context_all?`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.data && result.data.length > 0) {
          setDefaultOffering(result.data[0].context_name);
        }
        setFields(result.data);
        if (result.code && result.code === "token_not_valid") {
          setErrorMessage("Session expired, please login again");
        }
      })
      .catch((error) => console.error("error", error))
      .finally(() => {
        setLoading(false); // Set loading to false in the finally block
      });
  };

  // const deleteContext = (contextName) => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     access_token: a_t,
  //     offering_name: contextName,
  //   });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   fetch(`${baseUrl}/linkedin/context_delete_text`, requestOptions)
  //     .then((response) => response.text())
  //     .then((result) => {
  //       if (result.message && result.message === "user not found") {
  //         setErrorMessage("Session expired, please login again");
  //       }

  //       getContexts();
  //     })
  //     .catch((error) => console.log("error", error));
  // };
 
  const addContext = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${a_t}`);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        offering_name: newContext.context_name,
        offering_text: newContext.content,
      }),
      redirect: "follow",
    };

    fetch(`${baseUrl}/linkedin/context_add_text`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result.message && result.message === "user not found") {
          setErrorMessage("Session expired, please login again");
        }

        getContexts();
        setNewContext({
          context_name: "",
          content: "",
        });
      })
      .catch((error) => console.error("error", error));
  };

  //Edit functions and APIs
  const editContext = (contextName, newContent) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${a_t}`);

    const raw = JSON.stringify({
      offering_name: contextName,
      offering_text: newContent
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseUrl}/linkedin/context_edit_text`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        getContexts();
      })
      .catch((error) => console.error(error));
  };

  const toggleEditMode = (contextName) => {
    setEditMode((prevState) => ({
      ...prevState,
      [contextName]: !prevState[contextName] || false,
    }));
  };

  const handleEditSubmit = (contextName, newContent) => {
    toggleEditMode(contextName); // Toggle edit mode off
    editContext(contextName, newContent); // Call edit API
  };

  useEffect(() => {
    getContexts();
  },[]);

  const setDefaultOffering = (contextName) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30); // Set expiration to 30 days from now
    const expires = expirationDate.toUTCString();

    // Check if a default offering is already set
    const defaultOfferingCookie = document.cookie
      .split(";")
      .find((cookie) => cookie.trim().startsWith("default_offering="));
    if (defaultOfferingCookie) {
      // A default offering is already set, handle accordingly
      // console.log(
      //   "A default offering is already set. Clearing existing default offering before setting a new one."
      // );
      const existingDefaultOffering = defaultOfferingCookie.split("=")[1];
      if (existingDefaultOffering !== contextName) {
        // Clear the existing default offering if it's different from the one being set
        document.cookie = `default_offering=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
      } else {
        // The offering being set is already the default, no further action needed
        // console.log("The offering being set is already the default.");
        return;
      }
    }

    // Set the new default offering
    document.cookie = `default_offering=${contextName};expires=${expires};path=/`;
    window.location.reload(); // Reload the page after setting default
  };

  return (
    <div className="offerings-container">
      {loading && (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {
        !loading && !errorMessage &&
      (
        <div className="message-item">
        <Header/>
        <Navbar/>
          <input
            className="lead mb-1.5 p-2 neucha-regular"
            type="text"
            placeholder="Name of the offering"
            value={newContext.context_name}
            onChange={(e) =>
              setNewContext({ ...newContext, context_name: e.target.value })
            }
            maxLength="50"
          />
          <div className="counter">
            {newContext.context_name.length}/{MAX_CONTEXT_NAME_LENGTH}
          </div>
          <textarea
            className="mt-3 p-2 lead neucha-regular"
            placeholder="Description of the offering"
            value={newContext.content}
            maxLength="1000"
            onChange={(e) =>
              setNewContext({ ...newContext, content: e.target.value })
            }
          ></textarea>
          <div className="counter">
            {newContext.content.length}/{MAX_CONTENT_LENGTH}
          </div>
          {/* <button onClick={addContext}>Add</button> */}
          <div onClick={addContext} className="btn btn-outline-warning mt-3 ">
            Add
          </div>
        </div>
      )}
      {!errorMessage &&
        fields &&
        fields
          .slice(0)
          .reverse()
          .sort((a, b) => {
            if (
              document.cookie.includes(`default_offering=${a.context_name}`)
            ) {
              return -1;
            } else if (
              document.cookie.includes(`default_offering=${b.context_name}`)
            ) {
              return 1;
            }
            return 0;
          })
          .map((field) => (
            <div key={field.context_name} className="message-item">
              <div className="field-info">
                <h5>
                  <strong className="headings-messages">Offering name:</strong>
                </h5>
                <p className="mb-2 lead neucha-regular">{field.context_name}</p>
              </div>
              <div className="field-info">
                <h5>
                  <strong className="headings-messages">Content:</strong>
                </h5>
                {editMode[field.context_name] ? (
                  <div>
                    <textarea
                      type="text"
                      className="mb-2 lead neucha-regular"
                      value={field.content}
                      rows={Math.max(2, Math.ceil(field.content.length / 150))}
                      maxLength="2000"
                      onChange={(e) =>
                        setFields(
                          fields.map((item) =>
                            item.context_name === field.context_name
                              ? { ...item, content: e.target.value }
                              : item
                          )
                        )
                      }
                    ></textarea>
                    <div className="counter">
                      {field.content.length}/{MAX_CONTENT_LENGTH}
                    </div>
                  </div>
                ) : (
                  <p className="mb-2 lead neucha-regular">{field.content}</p>
                )}
              </div>
              {document.cookie.includes(
                `default_offering=${field.context_name}`
              ) ? (
                <button className="btn mt-3 btn-default">Default</button>
              ) : (
                <div>
                  {/* <button onClick={() => setDefaultOffering(field.context_name)}>Set as Default</button> */}
                  <button
                    onClick={() => setDefaultOffering(field.context_name)}
                    className="btn btn-outline-warning mt-3 "
                  >
                    Set as default
                  </button>
                </div>
              )}
              <div>
                {editMode[field.context_name] ? (
                  <button
                    onClick={() =>
                      handleEditSubmit(field.context_name, field.content)
                    }
                    className="btn btn-outline-warning mt-3"
                  >
                    Update
                  </button>
                ) : (
                  <button
                    onClick={() => toggleEditMode(field.context_name)}
                    className="btn btn-outline-warning mt-3"
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>
          ))}
    </div>
  );
};

export default Form2;
